<template>
  <v-container
    fluid
    tag="section"
  >
    <v-card flat>
      <v-btn
        absolute
        top
        right
        fab
        small
        @click="open(null)"
      >
        <v-icon>
          fa-plus
        </v-icon>
      </v-btn>

      <v-data-table
        :items="teamjudges"
        :headers="[
          { text: 'Nachname', value: 'person.familyName', sortable: true },
          { text: 'Vorname', value: 'person.givenName', sortable: true },
          { text: 'verfügbare Termine', value: 'available', sortable: false },
          { text: 'maximale Einsätze', value: 'max', sortable: false }
        ]"
        @click:row="open"
      >
        <template #item.person="{item}">
          {{ item.person | person }}
        </template>
        <template #item.available="{item}">
          <div v-for="a in item.available" :key="a">{{ a | date }}</div>
        </template>
      </v-data-table>
    </v-card>

    <base-material-dialog
      title="Kampfrichter für neutralen Einsatz melden"
      :value="dialog.open"
      icon="far fa-calendar"
      color="primary"
      :actions="[ !dialog.neu ? 'del' : '', dialog.data.person._id ? 'save' : '', 'cancel' ]"
      @close="close"
      @esc="close"
      @save="save"
      @del="del"
    >
      <v-card flat v-if="dialog.open">
        <v-row>
          <v-col cols="12" v-if="!dialog.neu">
            <v-text-field
              label="Kampfrichter"
              :value="`${dialog.data.person.familyName}, ${dialog.data.person.givenName}`"
              readonly
            />
          </v-col>
          <v-col cols="12" v-else>
            <base-edit-dialog-personsuche
              :value="dialog.data.person.familyName ? `${dialog.data.person.familyName}, ${dialog.data.person.givenName}` : ''"
              label="Kampfrichter"
              id=""
              @input="({value}) => { getPerson(value) }"
            />
          </v-col>
          <v-col sm="3" cols="4" v-for="d in dates" :key="d">
            <v-checkbox
              v-model="dialog.data.available"
              :label="d"
              :value="d"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Maximale Anzahl Einsätze (0 für egal)"
              v-model="dialog.data.max"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              label=Bemerkung
              v-model="dialog.data.comment"
              outlined
            />
          </v-col>
        </v-row>
      </v-card>
    </base-material-dialog>
  </v-container>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'
import gql from 'graphql-tag'

const query = `
  _id
  type
  ... on StbM2021League {
    teams {
      team {
        _id name
      }
      judges {
        person { _id givenName familyName }
        available
        comment
        max
      }
    }
  }
`

export default {
  name: 'judges',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    team: {
      type: String,
      required: true
    }
  },

  data: () => ({
    EventFind: [],
    dialog: {
      open: false,
      data: {
        person: { _id: null, givenName: 't', familyName: 't' }
      },
      neu: false
    },
    dates: [
      '2025-02-01',
      '2025-02-08',
      '2025-02-15',
      '2025-02-16',
      '2025-02-22',
      '2025-02-23',
      '2025-03-01',
      '2025-03-07',
      '2025-03-08',
      '2025-03-09',
      '2025-03-15',
      '2025-03-16',
      '2025-03-21',
      '2025-03-22',
      '2025-03-23',
      '2025-04-05',
      '2025-04-06',
      '2025-04-11',
      '2025-04-12',
      '2025-04-13',
      '2025-04-26',
      '2025-04-27',
      '2025-05-10'
    ],
    personen: {}
  }),

  computed: {
    league () {
      return this.EventFind?.find(e => !!e.teams?.find(t => t.team._id === this.team))
    },
    teamdata () {
      return this.league?.teams?.find(t => t.team._id === this.team)
    },
    teamname () {
      return this.league?.teams?.find(t => t.team._id === this.team)?.team?.name
    },
    teamjudges () {
      return this.league?.teams?.find(t => t.team._id === this.team)?.judges?.map(j => ({
        ...j,
        available: j.available.sort()
      }))
    }
  },

  methods: {
    open (item) {
      if (item === null) {
        this.dialog.data = {
          person: { _id: null, givenName: null, familyName: null },
          available: [],
          max: 0,
          comment: ''
        }
      } else {
        this.dialog.data = JSON.parse(JSON.stringify(item))
      }
      this.dialog.neu = item === null
      this.dialog.open = true
    },
    close () {
      this.dialog.data = { person: { _id: null, givenName: null, familyName: null } }
      this.dialog.open = false
    },
    save () {
      this.mutate({
        mutation: gql`mutation($id: UUID!, $team: UUID!, $person: UUID!, $available: [Date!], $comment: String!, $max: Int!) {
            StbM2021LigaTeamUpdateJudge(id: $id, team: $team, person: $person, available: $available, comment: $comment, max: $max) { ${query} }
          }`,
        variables: {
          id: this.league._id,
          team: this.team,
          person: this.dialog.data.person._id,
          available: this.dialog.data.available,
          comment: this.dialog.data.comment,
          max: parseInt(this.dialog.data.max)
        }
      }).then(() => this.close())
    },
    del () {
      this.mutate({
        mutation: gql`mutation($id: UUID!, $team: UUID!, $person: UUID!) {
            StbM2021LigaTeamDeleteJudge(id: $id, team: $team, person: $person) { ${query} }
          }`,
        variables: {
          id: this.league._id,
          team: this.team,
          person: this.dialog.data.person._id
        }
      }).then(() => this.close())
    },
    getPerson (id) {
      if (id) {
        this.query({
          query: gql`query($id: UUID!) {
              Person(id: $id) { _id familyName givenName }
            }`,
          variables: {
            id
          }
        }).then((data) => {
          this.dialog.data.person._id = data.Person._id
          this.dialog.data.person.familyName = data.Person.familyName
          this.dialog.data.person.givenName = data.Person.givenName
        })
      }

      return id
    }
  },

  apollo: {
    EventFind: {
      query: gql`
        query($parent: UUID) { EventFind(parent: $parent) { ${query} }}
      `,
      variables () {
        return {
          parent: this.id
        }
      }
    }
  }
}
</script>

<style scoped>
  .dates:before {
    display:block;
    content: ', ';
  }
</style>
