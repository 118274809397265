var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"absolute":"","top":"","right":"","fab":"","small":""},on:{"click":function($event){return _vm.open(null)}}},[_c('v-icon',[_vm._v(" fa-plus ")])],1),_c('v-data-table',{attrs:{"items":_vm.teamjudges,"headers":[
        { text: 'Nachname', value: 'person.familyName', sortable: true },
        { text: 'Vorname', value: 'person.givenName', sortable: true },
        { text: 'verfügbare Termine', value: 'available', sortable: false },
        { text: 'maximale Einsätze', value: 'max', sortable: false }
      ]},on:{"click:row":_vm.open},scopedSlots:_vm._u([{key:"item.person",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("person")(item.person))+" ")]}},{key:"item.available",fn:function(ref){
      var item = ref.item;
return _vm._l((item.available),function(a){return _c('div',{key:a},[_vm._v(_vm._s(_vm._f("date")(a)))])})}}])})],1),_c('base-material-dialog',{attrs:{"title":"Kampfrichter für neutralen Einsatz melden","value":_vm.dialog.open,"icon":"far fa-calendar","color":"primary","actions":[ !_vm.dialog.neu ? 'del' : '', _vm.dialog.data.person._id ? 'save' : '', 'cancel' ]},on:{"close":_vm.close,"esc":_vm.close,"save":_vm.save,"del":_vm.del}},[(_vm.dialog.open)?_c('v-card',{attrs:{"flat":""}},[_c('v-row',[(!_vm.dialog.neu)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Kampfrichter","value":((_vm.dialog.data.person.familyName) + ", " + (_vm.dialog.data.person.givenName)),"readonly":""}})],1):_c('v-col',{attrs:{"cols":"12"}},[_c('base-edit-dialog-personsuche',{attrs:{"value":_vm.dialog.data.person.familyName ? ((_vm.dialog.data.person.familyName) + ", " + (_vm.dialog.data.person.givenName)) : '',"label":"Kampfrichter","id":""},on:{"input":function (ref) {
      var value = ref.value;
 _vm.getPerson(value) }}})],1),_vm._l((_vm.dates),function(d){return _c('v-col',{key:d,attrs:{"sm":"3","cols":"4"}},[_c('v-checkbox',{attrs:{"label":d,"value":d},model:{value:(_vm.dialog.data.available),callback:function ($$v) {_vm.$set(_vm.dialog.data, "available", $$v)},expression:"dialog.data.available"}})],1)}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Maximale Anzahl Einsätze (0 für egal)"},model:{value:(_vm.dialog.data.max),callback:function ($$v) {_vm.$set(_vm.dialog.data, "max", $$v)},expression:"dialog.data.max"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Bemerkung","outlined":""},model:{value:(_vm.dialog.data.comment),callback:function ($$v) {_vm.$set(_vm.dialog.data, "comment", $$v)},expression:"dialog.data.comment"}})],1)],2)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }